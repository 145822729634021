.heading {
  color: #3d4152 !important;
  font-size: 1.28rem;
  text-transform: capitalize;
  margin-bottom: 0;
}

/* Desktop view */
@media only screen and (min-width: 1025px) {
  .heading {
    font-size: 24px;
  }
}
