.km-see-all {
  text-transform: uppercase;
  font-size: 0.93rem;
  font-weight: 500;
  color: #3d4152;
}

.km-see-all__icon {
  padding: 2px 5px 1px 6px;
  background-color: #fc8019;
  border-radius: 100%;
  color: #ffffff;
  font-weight: bold;
}
