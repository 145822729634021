.km-sub-heading {
  color: #93959f;
  line-height: 1.4;
  font-size: 0.93rem;
  font-weight: 400;
}

@media only screen and (min-width: 1025px) {
  .km-sub-heading {
    font-size: 16px;
  }
}
