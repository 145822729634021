.product-category-box {
  padding: 20px 0;
}

.category-slider-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0 0.7rem;
}

.category-slider-item {
  flex: 0 0 auto;
  width: 170px;
  height: 200px;
  border: 1px solid rgba(40, 44, 63, 0.1);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  background-color: #ffffff;
}

.category-slider-container::-webkit-scrollbar {
  display: none;
}
