.nav-bar-style {
  padding: 0.7rem 0;
  border-bottom: 1px solid rgba(40, 44, 63, 0.1);
  z-index: 1001;
}

.nav-search-bar__mobile {
  background-color: #ffffff;
  padding: 0.7rem 0;
  border-bottom: 1px solid rgba(40, 44, 63, 0.1);
  position: fixed;
  top: 0;
  z-index: 1001;
  transition: top 0.3s;
  width: 100%;
}

.nav-color {
  background-color: #ffffff;
}

.nav-cart-section {
  min-width: 60px;
}

.nav-cart-text {
  color: #181b29;
}

/* Desktop view */
@media only screen and (min-width: 1025px) {
  .nav-bar-style {
    position: fixed;
    top: 0;
  }
}
