body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  color: #2b3049;
  font-family: ProximaNova, Arial, Helvetica Neue, sans-serif;
}

a:hover {
  text-decoration: none;
  color: #3d4152;
}

.km-container {
  margin: 0 auto;
  max-width: 1200px;
}

.icon-cart,
.search-icon,
.icon-menu-bar,
.icon-search {
  color: #2b3049;
}

.cursor-pointer {
  cursor: pointer;
}

/* Desktop view */
@media only screen and (min-width: 1025px) {
  .km-container {
    padding: 67px 10px 10px 10px;
  }

  body {
    background-color: #ffffff;
  }
}
