.nav-search-bar {
  margin: 0 15px;
  border: 1px solid #d4d5d9;
  border-right: none;
  border-radius: 4px 0 0 4px;
  height: 35px;
}

.nav-search-icon {
  background-color: #ffffff;
  border: 1px solid #d4d5d9;
  border-left: none;
  border-radius: 0 4px 4px 0;
  padding: 0 0.7rem;
  transition: 0.3s;
}

.nav-search-bar:focus {
  outline: none;
}

.nav-search-icon:focus {
  outline: none;
}
